import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>自己紹介 | 猫背で進め</title>
    </Helmet>
    <h1>自己紹介</h1>
    <p>新しいものを作り始める瞬間が好きな Software Engineer です👨‍💻</p>
    <p>
      直近では、外資系コンサルティングファームの新規事業立ち上げを専門とする組織で働いていました。
    </p>

    <h2>Skills</h2>
    <ul>
      <li>Programming Language: Ruby, JavaScript/TypeScript, Elixir, PHP</li>
      <li>Web Development: Ruby on Rails, React, Next.js, Phoenix, CakePHP</li>
      <li>Mobile Development: React Native</li>
      <li>
        Infrastructure: AWS (Amplify, DynamoDB, RDS, S3, Lambda, ECS, Cognito,
        etc.), GCP (Firebase), Vercel
      </li>
      <li>Language: Japanese (Native), English (Fluent: IELTS 8.0)</li>
      <li>
        Others: Photography, Film Development, DTP (Illustrator, InDesign)
      </li>
    </ul>
    <h2>SNS</h2>
    <ul>
      <li>
        Twitter:{" "}
        <a href="https://twitter.com/alea12" target="_blank">
          @alea12
        </a>
      </li>
      <li>
        Instagram:{" "}
        <a href="https://instagram.com/_alea12" target="_blank">
          _alea12
        </a>
      </li>
    </ul>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
